import { useStaticQuery, graphql } from "gatsby"
import { useCore } from "@app/hooks/useCore"
import { useConfigContext } from "@app/providers/config"
import type { MetaTag } from "@root/types/global"

export const useMeta = () => {
  const {
    settings: { routes },
  } = useConfigContext()
  const { helpers } = useCore()
  const { global, languages, organisation, social, tracking } = useStaticQuery<GatsbyTypes.StaticMetaQuery>(graphql`
    query StaticMeta {
      global: sanityTemplateGlobal {
        title
        description
      }
      languages: sanitySettingLanguages {
        hrefLangs: languages {
          _key
          language
          region
          url
        }
      }
      organisation: sanitySettingOrganisation {
        title
        description
        author
        logo {
          asset {
            url
          }
        }
        addressLocality
        addressRegion
        postalCode
        streetAddress
        separator
        url
      }
      social: sanitySettingSocial {
        channels {
          title
          url
        }
      }
      tracking: sanitySettingTracking {
        facebookAppId
        googleSiteVerify
        googleSiteVerifyArray
        googleTagManagerId
      }
    }
  `)

  const mapping = {
    page: helpers.schemaWebSite,
    blog: helpers.schemaBlog,
    article: helpers.schemaArticle,
    product: helpers.schemaProduct,
    set: helpers.schemaProduct,
    collection: helpers.schemaWebSite,
    search: helpers.schemaSearch,
    store: helpers.schemaStore,
  }

  const excludeGoogleSiteVerifyFromSchema = ():
    | Omit<GatsbyTypes.SanitySettingTracking, "googleSiteVerify" | "googleSiteVerifyArray">
    | Record<string, never> => {
    if (!tracking) {
      return {}
    }
    const output = (Object.keys(tracking) as (keyof typeof tracking)[]).reduce(
      (result, key) => {
        if (key !== "googleSiteVerify" && key !== "googleSiteVerifyArray") {
          ;(result[key] as any) = tracking[key]
        }
        return result
      },
      {} as Omit<GatsbyTypes.SanitySettingTracking, "googleSiteVerify" | "googleSiteVerifyArray">
    )

    return output
  }

  const addGoogleSiteVerifyIntoMetaTags = (metaTags: MetaTag[]): MetaTag[] => {
    const cleanedMetaTags = metaTags.reduce(
      (result, tag) => (tag.name === "google-site-verification" ? result : [...result, tag]),
      [] as MetaTag[]
    )
    const newMetaTags = tracking?.googleSiteVerifyArray?.map(item => ({ name: "google-site-verification", content: item })) || []
    return [...cleanedMetaTags, ...newMetaTags]
  }

  const getData = ({ breadcrumbs, data, language, routes, template, url }) =>
    helpers.schemaData(
      { breadcrumbs, data, global, language, organisation, routes, social, template, tracking: excludeGoogleSiteVerifyFromSchema(), url },
      mapping
    )

  const getTags = (data: any) => addGoogleSiteVerifyIntoMetaTags(helpers.metaTags(data))

  const getLanguages = (route: any) =>
    languages?.hrefLangs?.map(({ _key, language, region, url }, index) => ({
      key: _key,
      primary: !index,
      href: `${url || organisation?.url}${route || ""}`,
      hrefLang: `${language}-${region}`,
      language: `${language}_${region}`,
      rel: "alternate",
    }))

  const getSchemas = (data: any) =>
    [helpers.schemaOrg(data), helpers.schemaContent(data), helpers.schemaBreadcrumbs(data)]?.filter(
      schema => schema["@type"] !== "Organization" || data?.pageUrl === `${data.siteURL}${routes.HOMEPAGE}`
    )

  return {
    getData,
    getLanguages,
    getSchemas,
    getTags,
  }
}
