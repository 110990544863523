import React, { memo, useCallback, useEffect, useMemo, useState } from "react"
import { Box, Flex, Text } from "@chakra-ui/react"

import { useAppContext } from "@app/providers/app"
import { useNavigationHeader, useNavigationLinkNormaliser } from "@app/hooks/useNavigationHeader"
import { HeaderNavigationDesktopTemplates } from "@app/components/Header/Navigation/HeaderNavigationDesktopTemplates"
import { Link } from "@app/components/Link"

type Props = {
  themeColour: string
  active: string | null
  setActive: (value: string | null) => void
}
export const HeaderNavigationDesktop: React.FC<Props> = memo(({ themeColour, active, setActive }) => {
  const [activeBackground, setActiveBackground] = useState<boolean>(false)
  const { dispatch, state } = useAppContext()
  const { header: items } = useNavigationHeader()
  const { normaliseLink } = useNavigationLinkNormaliser()

  const filteredItems = useMemo(() => {
    return items?.filter(item => !item._type?.includes("navigationSub"))
  }, [items])

  const activeSub = useMemo(
    () =>
      items?.find(
        ({ key, type }) =>
          (key === active && type?.includes("objectNavTemplate1")) ||
          type?.includes("objectNavTemplate2") ||
          type?.includes("objectNavTemplate3")
      )
        ? true
        : false,
    [active, items]
  )
  const handleActive = useCallback(state => setActive(state), [setActive])
  const handleClick = useCallback(() => setActive(null), [setActive])
  const handleClose = () => {
    handleActive(null)
    setActiveBackground(false)
  }

  useEffect(() => {
    if ((activeSub && !state.activeNavigation) || (!activeSub && state.activeNavigation))
      dispatch({
        type: "navigation",
        payload: activeSub ? true : false,
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSub])

  const handleTouch = useCallback(
    key => (activeSub && active === key ? handleActive(null) : handleActive(key)),
    [activeSub, active, handleActive]
  )

  useEffect(() => {
    if (active === null) setActiveBackground(false)
  }, [active])

  return filteredItems?.length > 0 ? (
    <>
      <Box
        onClick={() => {
          handleActive(null)
          setActiveBackground(false)
        }}
        position="fixed"
        bottom="0"
        left="0"
        zIndex={0}
        w="full"
        h="calc(100dvh - 158px)"
        cursor="pointer"
        pointerEvents={active ? "all" : "none"}
      >
        <Box
          opacity={activeBackground ? "1" : "0"}
          visibility={activeBackground ? "visible" : "hidden"}
          transition="all 0.2s ease"
          backdropFilter={activeBackground ? "blur(8px)" : "0"}
          w="full"
          h="full"
        />
        <Box
          background="grey.cloud"
          opacity={activeBackground ? "60%" : "0"}
          visibility={activeBackground ? "visible" : "hidden"}
          transition="all 0.2s ease"
          w="full"
          h="full"
        />
      </Box>
      <Flex as="nav" display={{ base: "none", md: "flex" }} alignItems="center" justifyContent="flex-start" ml="auto" mr="auto" zIndex={1}>
        {filteredItems.map(item => {
          const { _type: type, _key: key, link } = item

          const normalisedLink =
            type?.includes("objectNavTemplate1") || type?.includes("objectNavTemplate2") || type?.includes("objectNavTemplate3")
              ? normaliseLink(link?.[0] ? link?.[0] : null)
              : normaliseLink(item)

          return type?.includes("objectNavTemplate1") || type?.includes("objectNavTemplate2") || type?.includes("objectNavTemplate3") ? (
            // This element should be reworked into a button
            // It is a trigger element and we have manually added accessibility
            // But a button would be more appropriate
            <Box
              tabIndex={0}
              key={key}
              onClick={() => {
                if (active === key) {
                  handleActive(null)
                  setActiveBackground(false)
                } else {
                  handleActive(key)
                  setActiveBackground(true)
                }
              }}
              // The children elements are links
              // And dont need manual keydown
              onKeyDown={event => {
                if (event.key === "Enter") {
                  handleActive(key)
                  setActiveBackground(true)
                }
              }}
              onTouchStart={() => handleTouch(key)}
              paddingTop={{ base: 0, md: "22px" }}
              paddingBottom={{ base: 0, md: "18px" }}
              cursor="pointer"
            >
              <Flex px={{ md: 2, lg: 3 }} aria-label={item.title} cursor="pointer" justifyContent="center" alignItems="center">
                <Text
                  letterSpacing="wider"
                  size="sm"
                  transition="all 0.2s ease"
                  variant="caps"
                  lineHeight="1"
                  _hover={{
                    textDecoration: "underline",
                    textUnderlineOffset: "3px",
                    color: "grey.coal",
                  }}
                  {...(active !== null && active !== key
                    ? { color: "grey.mid" }
                    : active === key
                      ? {
                          textDecoration: "underline",
                          textUnderlineOffset: "3px",
                          color: "grey.coal",
                        }
                      : {
                          color: themeColour,
                        })}
                >
                  {item.title}
                </Text>
              </Flex>
              <HeaderNavigationDesktopTemplates
                active={active === key}
                handleActive={handleActive}
                handleClick={handleClick}
                item={item}
                handleClose={handleClose}
              />
            </Box>
          ) : normalisedLink ? (
            <Link
              key={key}
              display="inline-flex"
              px={{ md: 2, lg: 3 }}
              aria-label={item.title}
              to={normalisedLink.url}
              variant="ghost"
              cursor="pointer"
              paddingY={{ base: 0, md: 5 }}
            >
              <Text
                letterSpacing="wider"
                size="sm"
                transition="all 0.2s ease"
                variant="caps"
                {...(active !== null && active !== key
                  ? { color: "grey.mid" }
                  : active === key
                    ? {
                        textDecoration: "underline",
                        textUnderlineOffset: "3px",
                        color: "grey.coal",
                      }
                    : {
                        color: themeColour,
                      })}
              >
                {item.title}
              </Text>
            </Link>
          ) : null
        })}
      </Flex>
    </>
  ) : null
})
