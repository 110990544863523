import React, { memo, useMemo } from "react"
import { Divider, VStack } from "@chakra-ui/react"

import { useCartContext } from "@app/providers/cart"
import { CartListItem } from "@app/components/Cart/List/CartListItem"
import { useConfigContext } from "@app/providers/config"
import { CartLine } from "@shopify/hydrogen-react/storefront-api-types"

export const CartList: React.FC = memo(() => {
  const {
    settings: {
      cart: { gwpAttribute },
    },
  } = useConfigContext()
  const { cart, count } = useCartContext()

  const items = useMemo(() => {
    const isGwp = (item: CartLine) => item?.attributes?.find(x => gwpAttribute && x.key === gwpAttribute)
    return cart?.lines?.sort((a, b) => (!isGwp(a) ? 1 : !isGwp(b) ? -1 : 0))
  }, [gwpAttribute, cart?.lines])

  return count > 0 ? (
    <VStack
      alignItems="stretch"
      borderBottom="1px solid"
      borderBottomColor="grey.fog"
      pb={7.5}
      justifyContent="flex-start"
      divider={<Divider borderBottomColor="grey.fog" my={0} />}
      spacing={7.5}
      w="full"
    >
      {items?.map(item => <CartListItem key={item?.id} item={item} />)}
    </VStack>
  ) : null
})
