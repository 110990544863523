import React, { useCallback, useContext, useEffect, useMemo, useReducer, useRef, useState } from "react"

import { useConfigContext } from "@app/providers/config"
import { useCore } from "@app/hooks/useCore"
import { useDom } from "@app/hooks/useDom"
import { useStorage } from "@app/hooks/useStorage"

import type { CollectionCombinedProps, ProductProps, ProductVariantProps } from "@root/types/global"

type ReducerState = {
  activeBackground: string
  activeInversion: boolean
  activeMenu: boolean
  activeNavigation: boolean
  activeCart: boolean
  activeSearch: boolean
  activeSubscribe: boolean
}

type ReducerPayload = {
  [key: string]: string | boolean
}

type ReducerAction = {
  payload: string | boolean | ReducerPayload
  type: string
}

type ContextProps = {
  state: ReducerState
  dispatch: React.Dispatch<ReducerAction>
  calculateOffset: () => void
  activeCollection: CollectionCombinedProps | null
  setActiveCollection: (state: CollectionCombinedProps) => void
  activeHeaderHeight: number | null
  setActiveHeaderHeight: (state: number) => void
  activeHeaderOffset: number
  setActiveHeaderOffset: (state: number) => void
  activeProduct: ProductProps | null
  setActiveProduct: (state: ProductProps | null) => void
  activeSearches: Array<string>
  setActiveSearches: (state: Array<string>) => void
  activeVariant: ProductVariantProps | null
  setActiveVariant: (state: ProductVariantProps | null) => void
  isCartLoading: boolean
  setIsCartLoading: (state: boolean) => void
  announcementRef: React.Ref<HTMLDivElement>
  headerRef: React.Ref<HTMLDivElement>
}

export const AppContext = React.createContext<ContextProps | undefined>(undefined)

const INITIAL_VALUES = {
  activeBackground: "",
  activeCart: false,
  activeInversion: false,
  activeMenu: false,
  activeNavigation: false,
  activeSearch: false,
  activeSubscribe: false,
}

export const AppProvider: React.FC = ({ children }) => {
  const {
    settings: { keys },
  } = useConfigContext()
  const {
    helpers: { isBrowser },
  } = useCore()
  const { dom } = useDom()
  const { storage } = useStorage()
  const announcementRef = useRef<HTMLDivElement>(null)
  const headerRef = useRef<HTMLDivElement>(null)
  const [activeCollection, setActiveCollection] = useState<CollectionCombinedProps | null>(null)
  const [activeHeaderHeight, setActiveHeaderHeight] = useState<number | null>(null)
  const [activeHeaderOffset, setActiveHeaderOffset] = useState<number>(0)
  const [activeProduct, setActiveProduct] = useState<ProductProps | null>(null)
  const [activeSearches, setActiveSearches] = useState<Array<string>>(storage.get(keys.recent) || [])
  const [activeVariant, setActiveVariant] = useState<ProductVariantProps | null>(null)
  const [isCartLoading, setIsCartLoading] = useState<boolean>(false)

  const reducer = (state: ReducerState, action: ReducerAction) => {
    switch (action.type) {
      case "initial":
        return { ...state, ...INITIAL_VALUES }
      case "cart":
        return { ...state, activeCart: action.payload, activeSearch: false, activeMenu: false, activeSubscribe: false }
      case "menu":
        return { ...state, activeMenu: action.payload, activeSearch: false, activeCart: false, activeSubscribe: false }
      case "navigation":
        return { ...state, activeNavigation: action.payload }
      case "search":
        return { ...state, activeSearch: action.payload, activeCart: false, activeMenu: false, activeSubscribe: false }
      case "subscribe":
        return { ...state, activeSubscribe: action.payload, activeSearch: false, activeCart: false, activeMenu: false }
      case "theme":
        return { ...state, ...action.payload }
    }
  }

  const [state, dispatch] = useReducer<React.Reducer<ReducerState, ReducerAction>>(reducer, INITIAL_VALUES)

  const calculateOffset = useCallback(() => {
    const upperHeight =
      window?.scrollY >= announcementRef?.current?.clientHeight ? 0 : announcementRef?.current?.clientHeight - window?.scrollY

    const announcementHeight = upperHeight || 0
    const headerHeight = headerRef?.current?.clientHeight || 0
    const height = announcementHeight + headerHeight

    if (headerHeight !== activeHeaderHeight) setActiveHeaderHeight(headerHeight)
    if (height !== activeHeaderOffset) setActiveHeaderOffset(height)
  }, [activeHeaderHeight, activeHeaderOffset, headerRef, announcementRef, setActiveHeaderHeight, setActiveHeaderOffset])

  useEffect(() => {
    isBrowser && calculateOffset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dom.height, dom.width, state.activeCart, state.activeMenu, state.activeSearch])

  useEffect(() => {
    if (activeSearches && activeSearches !== storage.get(keys.recent)) storage.set(keys.recent, activeSearches)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSearches])

  const contextValue = useMemo<ContextProps>(
    () => ({
      state,
      dispatch,
      calculateOffset,
      activeCollection,
      setActiveCollection,
      activeHeaderHeight,
      setActiveHeaderHeight,
      activeHeaderOffset,
      setActiveHeaderOffset,
      activeProduct,
      setActiveProduct,
      activeSearches,
      setActiveSearches,
      activeVariant,
      setActiveVariant,
      isCartLoading,
      setIsCartLoading,
      announcementRef,
      headerRef,
    }),
    [
      state,
      calculateOffset,
      activeCollection,
      activeHeaderHeight,
      activeHeaderOffset,
      activeProduct,
      activeSearches,
      activeVariant,
      isCartLoading,
    ]
  )

  return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
}

export const useAppContext = (): ContextProps => ({ ...useContext(AppContext) }) as ContextProps
