import React, { useContext, useMemo } from "react"

import config from "@root/config.js"
import { useLocationContext } from "@app/providers/location"
import type { Config } from "@root/types/config"

type ContextProps = Config

export const ConfigContext = React.createContext<ContextProps | undefined>(undefined)

export const ConfigProvider: React.FC = ({ children }) => {
  const { store } = useLocationContext() as StoreLocation

  const activeConfig = useMemo<ContextProps>(
    () =>
      ({
        ...config,
        store: {
          ...store,
          ...config.services.shopify,
        },
        search: {
          ...config.services.reactify,
          searchIndex: store.searchIndex,
        },
        services: {
          ...config.services,
          sanity: {
            ...config.services.sanity,
          },
        },
      }) as Config,
    [store]
  )

  const contextValue = useMemo<ContextProps>(() => activeConfig, [activeConfig])

  return <ConfigContext.Provider value={contextValue}>{children}</ConfigContext.Provider>
}

export const useConfigContext = (): ContextProps => ({ ...useContext(ConfigContext) }) as ContextProps
