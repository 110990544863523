import React, { memo, useCallback, useMemo } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Divider, Flex, HStack, Text, VStack } from "@chakra-ui/react"

import { useCartContext } from "@app/providers/cart"
import { useConfigContext } from "@app/providers/config"
import { useCart } from "@app/hooks/useCart"
import { useShopify } from "@app/hooks/useShopify"
import { FormButton } from "@app/components/Form/FormButton"
import { CartPayments } from "./CartPayments"
import { totalDiscounts } from "@app/hooks/useShopify"

type Props = {
  isCartPage?: boolean
  disableCart?: boolean
}

export const CartSummary: React.FC<Props> = memo(({ isCartPage = false, disableCart = false }) => {
  const { cart } = useStaticQuery<GatsbyTypes.StaticCartSummaryQuery>(graphql`
    query StaticCartSummary {
      cart: sanityPageCart {
        additionalShipping
        additionalShippingRate
        additionalSubtotal
        additionalTotal
        additionalCheckout
      }
    }
  `)
  const {
    settings: {
      product: { giftCardType },
    },
  } = useConfigContext()
  const { cart: cartData, gotoCheckout, loading, warnings } = useCartContext()
  const { updateLineItemAttribute } = useCart()
  const { formatMoney } = useShopify()

  const handleCart = useCallback(async () => {
    const hasGiftcards = cartData?.lines?.filter(line => line?.merchandise?.product?.productType === giftCardType)

    if (hasGiftcards && hasGiftcards.length > 0) {
      const mutateLines = hasGiftcards
        ?.filter(line => {
          const dateValue = line.attributes?.find((attr: { key: string }) => attr.key === "_gift_recipient_delivery_date")?.value
          if (dateValue) {
            const today = new Date()
            today.setHours(0)
            today.setMinutes(0)
            const sendDate = new Date(dateValue)
            if (today > sendDate) {
              return true
            }
          }
          return false
        })
        ?.map(line => {
          const today = new Date()
          const formattedDate = `${String(today.getMonth() + 1).padStart(2, "0")}/${String(today.getDate()).padStart(
            2,
            "0"
          )}/${today.getFullYear()}`
          return {
            ...line,
            attributes: line.attributes.map((attribute: { key: string }) => {
              if (attribute.key === "_gift_recipient_delivery_date") {
                return { ...attribute, value: formattedDate }
              }
              return attribute
            }),
          }
        })

      if (mutateLines?.length > 0) {
        try {
          await updateLineItemAttribute(mutateLines)
        } catch (error) {
          console.error("Error updating cart before checkout", error)
          return
        }
      }
    }
    gotoCheckout()
  }, [cartData?.lines, giftCardType, gotoCheckout, updateLineItemAttribute])

  const subtotal = useMemo(() => formatMoney(cartData?.cost?.subtotalAmount?.amount), [cartData?.cost?.subtotalAmount?.amount, formatMoney])
  const total = useMemo(
    () => cartData?.cost.totalAmount.amount && formatMoney(Number(cartData?.cost.totalAmount.amount)),
    [formatMoney, cartData?.cost.totalAmount]
  )

  const totalDiscountNumber = totalDiscounts(cartData)

  return isCartPage ? (
    <Flex direction="column" alignItems="stretch" justifyContent="flex-start" w="full" maxW={["unset", 334]}>
      <VStack direction="column" alignItems="stretch" justifyContent="flex-start" w="full" spacing={6} mb={12}>
        <VStack alignItems="stretch" justifyContent="flex-start" spacing={4} w="full">
          {warnings?.length
            ? warnings.map(({ message, target }) => {
                return (
                  <HStack key={target} justify="space-between" mb={-1}>
                    <Text letterSpacing="generous" size="xs">
                      {message}
                    </Text>
                  </HStack>
                )
              })
            : null}
          <HStack justify="space-between" mb={-1}>
            <Text letterSpacing="generous" size="xs">
              {cart?.additionalSubtotal}
            </Text>
            <Text letterSpacing="generous" size="xs">
              {subtotal}
            </Text>
          </HStack>
          <HStack justify="space-between" mb={-1}>
            <Text letterSpacing="generous" size="xs">
              {cart?.additionalShipping}
            </Text>
            <Text letterSpacing="generous" size="xs">
              {cart?.additionalShippingRate}
            </Text>
          </HStack>
        </VStack>
        <Divider borderBottomColor="grey.fog" />
        <HStack justify="space-between" mb={-1}>
          <Text letterSpacing="generous" color="grey.coal" size="sm" fontWeight={700}>
            {cart?.additionalTotal}
          </Text>
          <Text letterSpacing="generous" color="grey.coal" size="sm" fontWeight={700}>
            {total}
          </Text>
        </HStack>
        <Divider borderBottomColor="grey.fog" />
      </VStack>
      <VStack direction="column" alignItems="stretch" justifyContent="flex-start" w="full" spacing={6}>
        <FormButton
          isLoading={loading}
          onClick={handleCart}
          size="md"
          title={cart?.additionalCheckout}
          aria-label={cart?.additionalCheckout}
          variant="primary"
          w="full"
        >
          {cart?.additionalCheckout}
        </FormButton>
        <CartPayments />
      </VStack>
    </Flex>
  ) : (
    <VStack alignItems="stretch" justifyContent="flex-start" spacing={isCartPage ? 6 : 4} w="full" maxW={isCartPage ? 334 : "unset"}>
      {warnings?.length
        ? warnings.map(({ message, target }) => {
            return (
              <HStack key={`${target}`}>
                <Text letterSpacing="generous" size="sm" color="grey.darker">
                  {message}
                </Text>
              </HStack>
            )
          })
        : null}

      {totalDiscountNumber.map((discount, index) => (
        <HStack key={`${discount.title}-${index}`} justifyContent="end">
          <Text letterSpacing="generous" size="sm" color="grey.mid">
            {discount.title}{" "}
          </Text>
          <Text letterSpacing="generous" size="sm" color="grey.mid">{`(-${formatMoney(discount.amount, discount.currencyCode)})`}</Text>
        </HStack>
      ))}
      <HStack justify="space-between" mb={-1}>
        <Text letterSpacing="generous" size="sm">
          {cart?.additionalSubtotal}
        </Text>
        <Text letterSpacing="generous" size="sm">{`— ${total}`}</Text>
      </HStack>
      <FormButton
        isLoading={loading}
        isDisabled={disableCart}
        onClick={handleCart}
        size="md"
        title={cart?.additionalCheckout}
        aria-label={cart?.additionalCheckout}
        variant="primary"
        w="full"
      >
        {cart?.additionalCheckout}
      </FormButton>
      <CartPayments />
    </VStack>
  )
})
