export const colors = {
  grey: {
    white: "#FFFFFF",
    cloud: "#F6F6F4",
    fog: "#CECFCC",
    mid: "#B4B3B4",
    lake: "#9C9EA0",
    darker: "#767676",
    darkest: "#272522",
    coal: "#43423E",
    overlay: "rgba(246, 246, 244, 0.5)",
    text: "#43423E",
    overlayDark: "rgba(67,66,62, 0.6)",
    navigationText: "#615D59",
  },
  brand: {
    sky: "#B3C4CC",
    kraft: "#C2AB8D",
    sand: "#BEB6AF",
    sunshine: "#F2CC63",
    beige: "#F9E794",
    sale: "#E68B78",
    success: "#41A43F",
    error: "#DE3B31",
  },
}
