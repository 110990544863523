import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"

import config from "@root/config.js"
import { useStorage } from "@app/hooks/useStorage"
import type { Config, StoreLocation } from "@root/types/config"

type ContextProps = StoreLocation & {
  loading: boolean
}

export const LocationContext = React.createContext<ContextProps | undefined>(undefined)

export const LocationProvider: React.FC = ({ children }) => {
  const { storage } = useStorage()

  const {
    store,
    stores,
    settings: { keys },
    services: { location },
  } = config as Config

  const [settings, setSettings] = useState({
    store,
    country: storage.get(keys.country) || "",
    location: storage.get(keys.location) || "",
    locations: Object.values(stores).map(({ store }) => store.siteLocation),
    locating: !storage.get(keys.country)?.length,
    visitor: !store.siteCountries?.includes(storage.get(keys.country)),
  })

  const [loading, setLoading] = useState(false)

  const updateLocation = useCallback(
    async (countryCode: string, updateCountry = false) => {
      setLoading(true)
      setSettings(prevState => ({
        ...prevState,
        country: updateCountry ? countryCode : settings.country,
        location: countryCode,
        locating: false,
      }))
      setLoading(false)
    },
    [settings]
  )

  const contextValue = useMemo<ContextProps>(
    () => ({
      ...settings,
      updateLocation,
      loading,
    }),
    [settings, updateLocation, loading]
  )

  useEffect(() => {
    if (settings.store && settings.country && settings.location) {
      storage.set(keys.country, settings.country)
      storage.set(keys.location, settings.location)
      storage.set(keys.shopify, settings.store.shopName)
    } else {
      try {
        fetch(location.serviceUrl)
          .then(res => res.json())
          .then(result => updateLocation(result[location.countryFieldKey] || store.siteLocation, true))
          .catch(() => updateLocation(store.siteLocation, true))
      } catch (e) {
        console.warn("Error locating country")
      }
    }
  }, [store, settings, location, keys, updateLocation, storage])

  return <LocationContext.Provider value={contextValue}>{children}</LocationContext.Provider>
}

export const useLocationContext = (): ContextProps => ({ ...useContext(LocationContext) }) as ContextProps
