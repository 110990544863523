import React, { useContext, useMemo } from "react"
import { graphql, useStaticQuery } from "gatsby"

type GlobalProps = GatsbyTypes.SanityTemplateGlobal & { additionalAddToCart: GatsbyTypes.SanityTemplateProduct["additionalAddToCart"] }

export const GlobalContext = React.createContext<GlobalProps | undefined>(undefined)

export const GlobalProvider: React.FC = ({ children }) => {
  const { global, product } = useStaticQuery(graphql`
    query StaticFeaturedProductsItem {
      global: sanityTemplateGlobal {
        messages
        additionalClear
        additionalClose
        additionalLoading
        additionalPrevious
        additionalNext
        additionalHide
        additionalShow
        additionalMenu
        additionalAccount
        additionalSearch
        additionalCart
        additionalWishlist
        additionalStores
        additionalAdd
        additionalFreeGift
        labelCollectionResults
        labelReadMore
        labelReadLess
        title
        description
      }
      product: sanityTemplateProduct {
        additionalAddToCart
      }
    }
  `)

  const contextValue = useMemo<GlobalProps>(() => ({ ...global, ...product }), [global, product])

  return <GlobalContext.Provider value={contextValue}>{children}</GlobalContext.Provider>
}

export const useGlobalContext = (): GlobalProps => ({ ...useContext(GlobalContext) }) as GlobalProps
