import React, { useContext, useMemo, useEffect, useState, useCallback } from "react"
import { useStaticQuery, graphql } from "gatsby"
import TagManager, { DataLayerArgs, TagManagerArgs } from "react-gtm-module"
import { globalHistory } from "@reach/router"

import { useCartContext } from "@app/providers/cart"
import { useConfigContext } from "./config"
import { useCore } from "@app/hooks/useCore"

export type ProductImpressionProps = {
  item_id: string
  item_name: string
  item_brand: string
  item_variant: string
  item_category: string
  item_list_name: string
  item_list_id: string
  index: number
  quantity: number | string
  price: number | string
}

export type ContextProps = {
  tracked: boolean
  setTracked: React.Dispatch<React.SetStateAction<boolean>>
  latestVersion: boolean
  colourOptionName: string
  gtm: {
    dataLayer: (dataLayerArgs: DataLayerArgs) => void
    initialize: (tagManagerArgs: TagManagerArgs) => void
  }
  setProductImpressionBatch: React.Dispatch<React.SetStateAction<Array<ProductImpressionProps>>>
  productImpressionBatch: Array<ProductImpressionProps>
  pushDataLayer: (datalayer: any) => void
}

export const TrackingContext = React.createContext<ContextProps | undefined>(undefined)

export const TrackingProvider: React.FC = ({ children }) => {
  const {
    settings: {
      product: { colourOptionName },
    },
  } = useConfigContext()
  const {
    helpers: { isDomReady, isDev },
  } = useCore()
  const { countryCode, currencyCode } = useCartContext()
  const [tracked, setTracked] = useState(false)
  const [productImpressionBatch, setProductImpressionBatch] = useState<Array<ProductImpressionProps>>([])
  const { tracking } = useStaticQuery<GatsbyTypes.StaticTrackingQuery>(graphql`
    query StaticTracking {
      tracking: sanitySettingTracking {
        facebookAppId
        googleSiteVerify
        googleSiteVerifyArray
        googleTagManagerId
      }
    }
  `)

  const pushDataLayer = useCallback(
    (dataLayer: any) => {
      if (TagManager) {
        isDev ? console.log({ ...dataLayer }) : TagManager.dataLayer({ dataLayer })
      }
    },
    [isDev]
  )

  const contextValue = useMemo<ContextProps>(
    () => ({
      tracked,
      setTracked,
      productImpressionBatch,
      setProductImpressionBatch,
      latestVersion: true,
      colourOptionName,
      gtm: TagManager,
      pushDataLayer,
    }),
    [tracked, setTracked, colourOptionName, productImpressionBatch, setProductImpressionBatch, pushDataLayer]
  )

  useEffect(() => {
    if (tracking?.googleTagManagerId && isDomReady) {
      const now = new Date()
      const timestampNow = now.getTime()
      const randomInt = Math.floor(Math.random() * 100000000000000)

      TagManager.initialize({
        gtmId: tracking?.googleTagManagerId,
        dataLayer: {
          event: "eventIDGenerated",
          timestampNow: timestampNow,
          randomInt: randomInt,
        },
      })
      setTracked(true)
    }
  }, [isDomReady, tracking])

  // intentionally only run once at certain times
  useEffect(() => {
    if (productImpressionBatch.length === 12) {
      pushDataLayer({
        event: "view_item_list",
        ecommerce: {
          currency: currencyCode,
          items: productImpressionBatch,
        },
      })
      setProductImpressionBatch([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productImpressionBatch.length, pushDataLayer])

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === "PUSH" && productImpressionBatch.length) {
        pushDataLayer({
          event: "view_item_list",
          ecommerce: {
            currency: countryCode,
            items: productImpressionBatch,
          },
        })
        setProductImpressionBatch([])
      }
    })
  }, [countryCode, productImpressionBatch, pushDataLayer])

  return isDomReady ? <TrackingContext.Provider value={contextValue}>{children}</TrackingContext.Provider> : <>{children}</>
}

export const useTrackingContext = (): ContextProps => ({ ...useContext(TrackingContext) }) as ContextProps
