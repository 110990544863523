import { useCallback } from "react"
import { getGatsbyImageData, GatsbyImageDataArgs } from "gatsby-source-sanity"

import { useConfigContext } from "@app/providers/config"
import { useCore } from "@app/hooks/useCore"
import { useMedia } from "@app/hooks/useMedia"
import { ImageProps } from "@root/types/global"

type ImageOptions = GatsbyImageDataArgs

type ResponsiveBreakpoint = "isSmall" | "isMedium" | "isLarge" | "isXLarge" | "isTouch"

type ResponsiveImageOptions = ImageOptions & {
  breakpoint?: ResponsiveBreakpoint
  desktop?: ImageOptions
  mobile?: ImageOptions
}

export const useImage = () => {
  const {
    services: { sanity },
  } = useConfigContext()
  const {
    helpers: { handleize },
  } = useCore()
  const media = useMedia()

  const filename = useCallback(
    ({ alt, asset }) => `${handleize(asset?.originalFilename?.split(`.`)?.[0] || alt)}.${asset?.extension}`,
    [handleize]
  )

  const withFilename = useCallback(
    (fluidImage = {}, name = "image.jpg", properties = ["src", "srcSet"]) => ({
      ...fluidImage,
      ...properties?.reduce((o, key) => ({ ...o, [key]: fluidImage[key]?.split("?")?.join(`/${name}?`) }), {}),
    }),
    []
  )

  const getGatsbyImage = useCallback(
    (image?: GatsbyTypes.SanityCustomImage | GatsbyTypes.SanityImage | undefined, options?: ImageOptions) =>
      image?.asset?.id
        ? {
            alt: image?.alt,
            dimensions: image?.asset?.metadata?.dimensions,
            ...withFilename(getGatsbyImageData(image, options || {}, sanity)?.images?.fallback, filename(image)),
          }
        : image,
    [filename, sanity, withFilename]
  )

  const getGatsbyVideo = useCallback(
    (video?: GatsbyTypes.SanityCustomVideo | undefined, options?: ImageOptions) =>
      video?.asset?.url
        ? {
            autoplay: video?.autoplay || false,
            loop: video?.loop || false,
            muted: video?.muted || false,
            poster: getGatsbyImage(video?.poster, options),
            src: video?.asset?.url,
            type: video?.asset?.mimeType,
          }
        : null,
    [getGatsbyImage]
  )

  const getResponsiveImage = useCallback(
    (image?: GatsbyTypes.SanityImageResponsive, options?: ResponsiveImageOptions) =>
      ({
        desktop: getGatsbyImage(image?.desktop, options?.desktop || options || {}),
        mobile: getGatsbyImage(image?.mobile?.asset ? image?.mobile : image?.desktop, options?.mobile || options?.desktop || options || {}),
      })[(options?.breakpoint ? media[options?.breakpoint] : !media["isBase"]) ? "desktop" : "mobile"],
    [getGatsbyImage, media]
  )

  const getResponsiveVideo = useCallback(
    (video?: GatsbyTypes.SanityVideoResponsive, options?: ResponsiveImageOptions) =>
      ({
        desktop: getGatsbyVideo(video?.desktop, options?.desktop || options || {}),
        mobile: getGatsbyVideo(
          video?.mobile?.asset?.url ? video?.mobile : video?.desktop,
          options?.mobile || options?.desktop || options || {}
        ),
      })[(options?.breakpoint ? media[options?.breakpoint] : !media["isBase"]) ? "desktop" : "mobile"],
    [getGatsbyVideo, media]
  )

  const sortGenderImages = useCallback((a: ImageProps, b: ImageProps) => {
    // Sorts the gender images based on their filenames
    const regex = /-(\d{1,2})([mf])([._-])/
    const matchA = a?.src.toLowerCase().match(regex)
    const matchB = b?.src.toLowerCase().match(regex)

    // Check if both images have a match
    if (matchA && matchB) {
      const [, numA, genderA] = matchA
      const [, numB, genderB] = matchB

      // If the numbers are the same, prioritize female images over male images
      if (numA === numB) {
        if (genderA === "f" && genderB === "m") {
          return -1
        } else if (genderA === "m" && genderB === "f") {
          return 1
        }
      }

      // Sort the images based on the number in their filenames
      return parseInt(numA) - parseInt(numB)
    }

    // If there is no match or the images are equal, return 0
    return 0
  }, [])

  return { getGatsbyImage, getGatsbyVideo, getResponsiveImage, getResponsiveVideo, sortGenderImages }
}
